html.page--iaaj {
  /*html[dir=ltr]*/
  /*html[dir=ltr]*/
}
html.page--iaaj #menu {
  display: none;
}
html.page--iaaj .page__withoutmenu {
  margin-left: 0 !important;
}
html.page--iaaj .menuheader__header {
  padding-left: 10px !important;
}
html.page--iaaj .menuheader--logotitle .sitetitle__logo {
  margin-left: 10px !important;
}
html.page--iaaj .menuheader__hamburger {
  display: none;
}
html.page--iaaj strong {
  font-weight: 600;
}

.iaajregistration-switch {
  display: flex;
  align-self: center;
  margin-top: 30px;
  font-weight: 600;
}

.iaajregistration-switch input + label {
  flex: none;
  margin-right: 25px;
}

html.page--iaaj input[type=text],
html.page--iaaj input[type=email],
html.page--iaaj select,
html.page--iaaj .ut-pulldown {
  height: 48px;
}
html.page--iaaj .ut-pulldown__current {
  height: 100%;
  display: inline-flex;
  align-items: center;
}