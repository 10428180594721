/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
.climateexperts-graph {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 300ms;
}
.climateexperts-graph__grouplabel {
  fill: #3f3f3f;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}
.climateexperts-graph__centerarea {
  position: absolute;
  width: 18%;
  aspect-ratio: 1/1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.climateexperts-graph__centerarea > h2 {
  margin: 0 !important;
  font-size: clamp(24px, 3dvw, 30px);
  line-height: 100%;
  text-align: center;
}
.climateexperts-graph__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
}
.climateexperts-graph--ready {
  opacity: 1;
}
.climateexperts-graph svg {
  position: relative;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  z-index: 0;
}
.climateexperts-graph--ready .climateexperts-graph__grouplabel {
  opacity: 1;
}
.climateexperts-graph .domain__circle {
  transform-origin: 0 0;
  cursor: pointer;
  transition: all 300ms;
}
.climateexperts-graph .subdomain {
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms;
}
.climateexperts-graph .subdomain.disabled {
  pointer-events: none;
}
.climateexperts-graph .subdomain.disabled .subdomain__circle {
  opacity: 0.2;
}
.climateexperts-graph .subdomain__label {
  font-size: 20px;
  font-weight: 500;
  line-height: 90%;
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: opacity 300ms;
}
.climateexperts-graph .subdomain__label.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.climateexperts-graph .subdomain__label--left {
  justify-content: flex-end;
}
.climateexperts-graph .subdomain__label__text {
  color: #3f3f3f;
  width: clamp(170px, 17dvw, 250px);
  flex: none;
}
.climateexperts-graph .subdomain__label__text--left {
  text-align: right;
}
.climateexperts-graph .dot {
  transform-origin: center;
  cursor: pointer;
  transition: opacity 300ms;
  opacity: 0;
}
.climateexperts-graph .dot.disabled {
  opacity: 0.1;
  pointer-events: none;
}
.climateexperts-graph .dot__inner {
  transform-origin: center;
  transition: transform 200ms;
}
.climateexperts-graph .dot:hover .dot__inner {
  transform: scale(1.2);
}
.climateexperts-graph .dot__bgcircle, .climateexperts-graph .dot__line {
  opacity: 0;
  transition: opacity 200ms;
  pointer-events: none;
}
.climateexperts-graph .dot:hover .dot__bgcircle, .climateexperts-graph .dot:hover .dot__line, .climateexperts-graph .dot .dot__line.hover-subdomain {
  opacity: 1;
}
.climateexperts-graph--ready .circlepos-0 {
  transition: opacity 300ms;
}
.climateexperts-graph--ready .circlepos-1 {
  transition: opacity 300ms 50ms;
}
.climateexperts-graph--ready .circlepos-2 {
  transition: opacity 300ms 100ms;
}
.climateexperts-graph--ready .circlepos-3 {
  transition: opacity 300ms 150ms;
}
.climateexperts-graph--ready .circlepos-4 {
  transition: opacity 300ms 200ms;
}
.climateexperts-graph--ready .circlepos-5 {
  transition: opacity 300ms 250ms;
}
.climateexperts-graph--ready .circlepos-6 {
  transition: opacity 300ms 300ms;
}
.climateexperts-graph--ready .circlepos-7 {
  transition: opacity 300ms 350ms;
}
.climateexperts-graph--ready .circlepos-8 {
  transition: opacity 300ms 400ms;
}
.climateexperts-graph--afterready .dot {
  opacity: 1;
}