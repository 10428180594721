/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.widget--nominations {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
  padding-left: 15px;
  padding-right: 15px;
}

.widget--nominations__title {
  font: 600 26px "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 35px;
}

.nominations__items {
  display: grid;
  position: relative;
  max-width: 779px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: #FFFFFF;
  border-radius: 4px;
}

.nominations__item {
  grid-column: 1;
  grid-row: 1;
  display: flex !important;
  align-items: center;
  padding: 35px 45px;
  padding-left: 130px;
}
@media (max-width: 799px) {
  .nominations__item {
    padding: 35px 25px;
    text-align: center;
  }
}

/*
.nominations__item__photo
{
  position: absolute;
  left: 45px;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  background-color: #AAAAAA;
}
*/
.nominations__item__confetti {
  position: absolute;
  left: -20px;
  width: 245px;
  top: 10px;
}

.nominations__item__text {
  position: relative;
  width: 100%;
}

.nominations__item__name {
  font: 600 17px "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.nominations__item__reason {
  font: 300 17px "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  color: #777777;
}

@media (max-width: 799px) {
  .widget--nominations {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media (min-width: 800px) {
  .widget--nominations {
    padding-top: 125px;
    padding-bottom: 70px;
  }
}
.nominations__item {
  opacity: 0;
  transition: opacity 0.2s;
}

.nominations__item.activeslide {
  opacity: 1;
}

/*
// State when active
.nominations__items
{
  @include fading-curslide();

  & + &
  {
    display: none; // Show only the first slide
  }
}

// State before fading in
.nominations__item.slideshow--slidein
{
  @include fading-newslide();
}

// State after fading out
.nominations__item.slideshow--slideout
{
  @include fading-oldslide();
}
*/
/*
.header__sliderdots
{
  bottom: 16px;
  color: #ffffff;
  position: absolute;
  width: 100%;
  z-index: 10;

  .header__container--withfilters &
  {
    // prevent overlapping the "show filters" toggle button
    bottom: 6px;
  }

  html.site--itc &
  {
    bottom: 34px;
  }

  // Don't show the slider dots
  @media (max-width: 480px)
  {
    display: none;
  }
}

.header__sliderdots-contents
{
  display: flex;
}

.header__sliderdot
{
  @include sliderdot();
}

.header__sliderdotactive
{
  @include sliderdot-active();
}
*/