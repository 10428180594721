/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
html.searchsheet--open body {
  overflow: hidden;
}

.searchsheet {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;
  background: var(--color-white);
  bottom: 0;
  box-sizing: content-box;
  left: 260px;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4;
}
html.searchsheet--open .searchsheet {
  opacity: 1;
}

html.searchsheet--opening:not(.searchsheet--fixed) .searchsheet {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.searchsheet--closing:not(.searchsheet--fixed) .searchsheet {
  transition-delay: 300ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

html.searchsheet--open .searchsheet {
  pointer-events: auto;
}

html[dir=ltr].hidesidemenu .searchsheet {
  left: 0;
}
@media (max-width: 1220px) {
  .searchsheet {
    left: 0;
  }
}
.searchsheet--init {
  transition: none;
}
.searchsheet.searchsheet--showquicklinks, .searchsheet.searchsheet--showquickresults, .searchsheet.searchsheet--inputfocus {
  background: #f0f1f2;
}

@media (min-width: 481px) {
  .searsia__resultsdone {
    display: none;
  }
}
.searchsheet__login-msgbox {
  margin-bottom: 30px;
  position: relative;
}
.searchsheet__login-msgbox a {
  color: var(--color-tahiti-gold);
}
.searchsheet__login-msgbox__content {
  border-radius: 4px;
  background-color: rgba(236, 122, 8, 0.1);
  border: 1px solid rgba(236, 122, 8, 0.2);
  padding: 10px 45px 10px 20px;
  position: relative;
}
.searchsheet__login-msgbox p {
  margin-top: 0;
  margin-bottom: 0;
}

.searchsheet__content {
  height: 100%;
  width: 100%;
}

.searchsheet__header {
  align-items: center;
  background: #f0f1f2;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 0 0 0;
  height: 80px;
  overflow: auto;
  position: relative;
  width: 100%;
}
.searchsheet--showquicklinks .searchsheet__header, .searchsheet--showquickresults .searchsheet__header, .searchsheet--inputfocus .searchsheet__header {
  background: var(--color-white);
}
html.hidesidemenu .searchsheet--showquicklinks .searchsheet__header, html.hidesidemenu .searchsheet--showquickresults .searchsheet__header, html.hidesidemenu .searchsheet--inputfocus .searchsheet__header {
  border-color: #dcddde;
}
@media (max-width: 1220px) {
  .searchsheet--showquicklinks .searchsheet__header, .searchsheet--showquickresults .searchsheet__header, .searchsheet--inputfocus .searchsheet__header {
    border-color: #dcddde;
  }
}
@media (max-width: 750px) {
  .searchsheet__header {
    height: 60px;
  }
}

.searchsheet__searchform {
  opacity: 0;
  transform: translateY(20px);
  transition-property: opacity, transform;
  margin-left: calc(50% - 460px);
  margin-top: 20px;
  width: 920px;
  position: relative;
}
html.searchsheet--open .searchsheet__searchform {
  opacity: 1;
  transform: translateY(0);
}

html.searchsheet--close:not(.searchsheet--fixed) .searchsheet__searchform {
  transform: translateY(-20px);
}

html.searchsheet--opening:not(.searchsheet--fixed) .searchsheet__searchform {
  transition-delay: 100ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.searchsheet--closing:not(.searchsheet--fixed) .searchsheet__searchform {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

@media (max-width: 1440px) {
  .searchsheet__searchform {
    width: calc(50% + 330px);
  }
}
@media (max-width: 1220px) {
  .searchsheet__searchform {
    width: 920px;
  }
}
@media (max-width: 960px) {
  .searchsheet__searchform {
    margin-left: 90px;
    margin-right: 270px;
    width: auto;
  }
}
@media (max-width: 750px) {
  .searchsheet__searchform {
    margin-left: 28px;
    margin-right: 146px;
    margin-top: 10px;
  }
}
@media (max-width: 480px) {
  .searchsheet__searchform {
    margin-left: 22px;
    margin-right: 134px;
    margin-top: 10px;
  }
}
.searchsheet__searchform .searchsheet__searchicon {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e2328;
  display: inline-block;
  flex: none;
  font-size: 24px;
  height: 20px;
  text-align: left;
  width: 50px;
}
.searchsheet__searchform .searchsheet__searchicon::before {
  content: "\e96b";
}
@media (max-width: 750px) {
  .searchsheet__searchform .searchsheet__searchicon::before {
    content: "\e945";
  }
}
.searchsheet__searchform .searchsheet__searchfield {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  color: #1e2328;
  display: inline-block;
  font-size: 26px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0 24px 0 0;
  width: calc(100% - 50px);
}
.searchsheet__searchform .searchsheet__searchfield::-webkit-input-placeholder {
  color: #afb1b3;
  opacity: 1;
}
.searchsheet__searchform .searchsheet__searchfield::-moz-placeholder {
  color: #afb1b3;
  opacity: 1;
}
.searchsheet__searchform .searchsheet__searchfield::placeholder {
  color: #afb1b3;
  opacity: 1;
}
.searchsheet__searchform .searchsheet__searchfield:focus {
  outline: none;
}
.searchsheet__searchform .searchsheet__searchfield::-ms-clear {
  display: none;
}
@media (max-width: 750px) {
  .searchsheet__searchform .searchsheet__searchfield {
    font-size: 18px;
  }
  .searchsheet__searchform .searchsheet__searchicon {
    width: 38px;
    font-size: 16px;
  }
}
.searchsheet__searchform .searchsheet__searchfield-clear {
  color: #afb1b3;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 0px;
  text-align: center;
  text-decoration: none;
  top: 8px;
  width: 24px;
}

.searchsheet__close {
  opacity: 0;
  transform: translateY(20px);
  transition-property: opacity, transform;
  background: var(--color-white);
  border-radius: 20px;
  color: #1e2328;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  text-decoration: none;
  width: 90px;
}
html.searchsheet--open .searchsheet__close {
  opacity: 1;
  transform: translateY(0);
}

html.searchsheet--close:not(.searchsheet--fixed) .searchsheet__close {
  transform: translateY(-20px);
}

html.searchsheet--opening:not(.searchsheet--fixed) .searchsheet__close {
  transition-delay: 100ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.searchsheet--closing:not(.searchsheet--fixed) .searchsheet__close {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

html.searchsheet--fixed .searchsheet__close {
  display: none;
}
.searchsheet--showquicklinks .searchsheet__close, .searchsheet--showquickresults .searchsheet__close, .searchsheet--inputfocus .searchsheet__close {
  background: #f0f1f2;
}
@media (max-width: 750px) {
  .searchsheet__close {
    margin-top: -10px;
  }
}

html.utwente--ws2021 .searchsheet__close-label {
  display: none;
}

.searchsheet__container {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  /*  @media (max-width: 960px)
    {
      display: block;
    }*/
}

.searchsheet__quicklinks,
.searchsheet__quickresults,
.searchsheet__results {
  display: none;
  margin: 50px auto;
  max-width: 920px;
  width: 100%;
}
@media (max-width: 960px) {
  .searchsheet__quicklinks,
  .searchsheet__quickresults,
  .searchsheet__results {
    margin-left: 90px;
    margin-right: 90px;
    width: auto;
  }
}
@media (max-width: 750px) {
  .searchsheet__quicklinks,
  .searchsheet__quickresults,
  .searchsheet__results {
    margin-left: 28px;
    margin-right: 28px;
  }
}
@media (max-width: 480px) {
  .searchsheet__quicklinks,
  .searchsheet__quickresults,
  .searchsheet__results {
    margin-left: 22px;
    margin-right: 22px;
  }
}

.searchsheet__quicklinks,
.searchsheet__quickresults {
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  justify-content: flex-start;
  max-width: 820px;
  overflow: visible;
}
@media (max-width: 960px) {
  .searchsheet__quicklinks,
  .searchsheet__quickresults {
    margin-left: 140px;
    margin-right: 140px;
  }
}
@media (max-width: 750px) {
  .searchsheet__quicklinks,
  .searchsheet__quickresults {
    margin-left: 72px;
    margin-right: 72px;
  }
}

.searchsheet__quicklinks {
  opacity: 0;
  transform: translateY(20px);
  transition-property: opacity, transform;
}
html.searchsheet--open .searchsheet__quicklinks {
  opacity: 1;
  transform: translateY(0);
}

html.searchsheet--close:not(.searchsheet--fixed) .searchsheet__quicklinks {
  transform: translateY(-20px);
}

html.searchsheet--opening:not(.searchsheet--fixed) .searchsheet__quicklinks {
  transition-delay: 100ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.searchsheet--closing:not(.searchsheet--fixed) .searchsheet__quicklinks {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

.searchsheet--showquicklinks .searchsheet__quicklinks {
  display: flex;
}

.searchsheet__quickresults {
  opacity: 0;
  transform: translateY(20px);
  transition-property: opacity, transform;
  flex-direction: column;
}
html.searchsheet--open .searchsheet__quickresults {
  opacity: 1;
  transform: translateY(0);
}

html.searchsheet--close:not(.searchsheet--fixed) .searchsheet__quickresults {
  transform: translateY(-20px);
}

html.searchsheet--opening:not(.searchsheet--fixed) .searchsheet__quickresults {
  transition-delay: 100ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.searchsheet--closing:not(.searchsheet--fixed) .searchsheet__quickresults {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

.searchsheet--showquickresults .searchsheet__quickresults {
  display: flex;
}

.searchsheet__results {
  opacity: 0;
  transform: translateY(20px);
  transition-property: opacity, transform;
  margin-top: 0;
}
html.searchsheet--open .searchsheet__results {
  opacity: 1;
  transform: translateY(0);
}

html.searchsheet--close:not(.searchsheet--fixed) .searchsheet__results {
  transform: translateY(-20px);
}

html.searchsheet--opening:not(.searchsheet--fixed) .searchsheet__results {
  transition-delay: 100ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.searchsheet--closing:not(.searchsheet--fixed) .searchsheet__results {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

.searchsheet--showresults .searchsheet__results {
  display: block;
}

.searchsheet__section {
  flex: none;
  margin-bottom: 24px;
  padding-right: 24px;
  width: 33%;
  /*  @media (max-width: 960px)
    {
      border-color: var(--color-grey-dark);
      border-style: solid;
      border-width: 0 0 1px 0;
      width: 100%;

      & + &
      {
        padding: 0;
      }

      // First link section has a top border as well
      &:first-of-type
      {
        border-top-width: 1px;
      }
    }*/
}
@media (max-width: 960px) {
  .searchsheet__section {
    width: 50%;
  }
}
@media (max-width: 750px) {
  .searchsheet__section {
    width: 100%;
  }
}

.searchsheet__section-title {
  color: #1e2328;
  display: inline;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  line-height: 36px;
}
.searchsheet__section-title::after {
  content: ":";
}

.searchsheet__section-icon {
  display: none;
}

.searchsheet__section-links {
  overflow: visible;
}

.searchsheet__section-link {
  color: #53575b;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 24px;
  position: relative;
  text-decoration: none;
}
.searchsheet__section-link + .searchsheet__section-link {
  margin-top: 10px;
}
.searchsheet__section-link-icon {
  color: #afb1b3;
  font-size: 16px;
  left: -24px;
  padding-right: 13px;
  position: absolute;
  top: 4px;
}
.searchsheet__section-link--showall {
  margin-left: 0;
  margin-top: 10px;
  text-decoration: underline;
}
.searchsheet__section-link:hover, .searchsheet__section-link:hover .searchsheet__section-link-icon {
  color: #1e2328;
}

.searchsheet__suggestmatch {
  color: #1e2328;
  font-weight: 600;
}

.searchsheet__section-alllinks {
  height: 0;
  margin-top: 10px;
  opacity: 0;
  overflow: hidden;
  transition: height 250ms, opacity 250ms;
}
.searchsheet__section-alllinks--open {
  opacity: 1;
}
.searchsheet__section-alllinks--init {
  height: auto;
  position: absolute;
  top: 0;
  transition: none;
  visibility: hidden;
}

.searchsheet__tabs {
  overflow: hidden;
  min-height: 55px; /* 54 + 1px border */
}
@media (max-width: 960px) {
  .searchsheet__tabs {
    margin-left: -90px;
    margin-right: -90px;
  }
}
@media (max-width: 750px) {
  .searchsheet__tabs {
    margin-left: -28px;
    margin-right: -28px;
  }
}
@media (max-width: 480px) {
  .searchsheet__tabs {
    margin-left: -22px;
    margin-right: -22px;
  }
}

.searchsheet__tabscontainer {
  border-color: #dcddde;
  border-style: solid;
  border-width: 0 0 1px;
  display: inline-block;
  min-width: 100%;
  white-space: nowrap;
}
@media (max-width: 960px) {
  .searchsheet__tabscontainer {
    margin-left: 90px;
    margin-right: 90px;
    min-width: calc(100% - 180px);
  }
}
@media (max-width: 750px) {
  .searchsheet__tabscontainer {
    margin-left: 28px;
    margin-right: 28px;
    min-width: calc(100% - 56px);
  }
}
@media (max-width: 480px) {
  .searchsheet__tabscontainer {
    margin-left: 22px;
    margin-right: 22px;
    min-width: calc(100% - 44px);
  }
}

.searchsheet__tab {
  border-color: transparent;
  border-style: solid;
  border-width: 0 0 2px;
  color: #53575b;
  display: inline-block;
  font-size: 14px;
  height: 54px;
  line-height: 54px;
  margin-bottom: -1px;
  padding: 0 10px;
  text-decoration: none;
}
.searchsheet__tab--current {
  border-color: #1e2328;
  color: #1e2328;
  font-weight: 600;
}
.searchsheet__tab + .searchsheet__tab {
  margin-left: 20px;
}

.searchsheet__status {
  font-size: 13px;
  margin: 20px 0;
}

.searchsheet__didyoumean {
  color: #53575b;
  cursor: pointer;
  text-decoration: underline;
}
.searchsheet__didyoumean:hover {
  color: inherit;
}

.searsia__results {
  position: relative;
}
h2 + .searsia__results, h3 + .searsia__results, h4 + .searsia__results {
  margin-top: 10px;
}

.searsia__result {
  clear: both;
  color: #53575b;
  display: block;
  margin: 20px 0;
  overflow: auto;
  text-decoration: none;
  /* default 9col */
}
@media (max-width: 960px) {
  .searsia__result {
    width: calc((100% - 20px - 11 * 30px) / 12 * 9 + 8 * 30px);
    width: calc((100% - var(--pageside-padding) * 2 - 11 * 30px) / 12 * 9 + 8 * 30px);
  }
}
@media (min-width: 961px) {
  .searsia__result {
    width: 690px;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .searsia__result {
    width: 825px;
  }
}
.searsia__result--news {
  /* 10col */
}
@media (max-width: 960px) {
  .searsia__result--news {
    width: calc((100% - 20px - 11 * 30px) / 12 * 10 + 9 * 30px);
    width: calc((100% - var(--pageside-padding) * 2 - 11 * 30px) / 12 * 10 + 9 * 30px);
  }
}
@media (min-width: 961px) {
  .searsia__result--news {
    width: 770px;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .searsia__result--news {
    width: 920px;
  }
}
.searsia__result--news .searsia__title, .searsia__result--news .searsia__desc {
  margin-left: 20px;
}
@media (max-width: 960px) {
  .searsia__result {
    width: 100%;
  }
}
.searsia__result--people {
  cursor: pointer;
  margin: 20px -20px;
  padding: 0 20px;
}
@media (max-width: 960px) {
  .searsia__result--people {
    width: calc(100% + 40px);
  }
}

.searsia__results .searsia__widget--cta:not(:empty) ~ .searsia__result,
.searsia__results .searsia__widget--location:not(:empty) ~ .searsia__result,
.searsia__results .searsia__widget--event:not(:empty) ~ .searsia__result {
  /* 7col to create space for small widget */
}
@media (max-width: 960px) {
  .searsia__results .searsia__widget--cta:not(:empty) ~ .searsia__result,
  .searsia__results .searsia__widget--location:not(:empty) ~ .searsia__result,
  .searsia__results .searsia__widget--event:not(:empty) ~ .searsia__result {
    width: calc((100% - 20px - 11 * 30px) / 12 * 7 + 6 * 30px);
    width: calc((100% - var(--pageside-padding) * 2 - 11 * 30px) / 12 * 7 + 6 * 30px);
  }
}
@media (min-width: 961px) {
  .searsia__results .searsia__widget--cta:not(:empty) ~ .searsia__result,
  .searsia__results .searsia__widget--location:not(:empty) ~ .searsia__result,
  .searsia__results .searsia__widget--event:not(:empty) ~ .searsia__result {
    width: 530px;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .searsia__results .searsia__widget--cta:not(:empty) ~ .searsia__result,
  .page--toplevelhome .searsia__results .searsia__widget--location:not(:empty) ~ .searsia__result,
  .page--toplevelhome .searsia__results .searsia__widget--event:not(:empty) ~ .searsia__result {
    width: 635px;
  }
}

.searsia__result {
  border: 1px solid transparent;
  margin: 0 -20px;
  padding: 20px;
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  -webkit-touch-callout: none;
}
.searsia__result:hover {
  background: #f7f8f8;
  border-color: #dcddde;
  color: #53575b;
}
@media (max-width: 960px) {
  .searsia__result {
    width: calc(100% + 40px) !important;
  }
}
@media (hover: none) {
  .searsia__result:hover {
    background: none;
    border-color: transparent;
    color: inherit;
  }
}

.searsia__debuginfo {
  color: #afb1b3;
  float: right;
  font-size: 10px;
}
.searsia__imagegrid__item .searsia__debuginfo {
  background-color: rgba(255, 255, 255, 0.5);
  color: #53575b;
  float: none;
  line-height: normal;
  padding: 2px 4px;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.searsia__thumb {
  background-position: center;
  background-size: cover;
  display: block;
  float: left;
  height: 95px;
  margin-right: 20px;
  position: relative;
  width: 140px;
}
@media (max-width: 960px) {
  .searsia__thumb {
    height: 100px;
    width: 150px;
  }
}
@media (max-width: 750px) {
  .searsia__thumb {
    height: 92px;
    width: 135px;
  }
}
@media (max-width: 480px) {
  .searsia__thumb {
    height: 92px;
    width: 86px;
  }
}
.searsia__result--people .searsia__thumb {
  border-radius: 100%;
  height: 70px;
  width: 70px;
}

.searsia__toggle {
  color: #afb1b3;
  cursor: pointer;
  display: block;
  float: right;
  height: 70px;
  line-height: 70px;
  padding: 0 20px;
}
.searsia__result--people:hover .searsia__toggle {
  color: #53575b;
}
.searsia__toggle::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e92f";
}
.searsia__result--open .searsia__toggle {
  color: #1e2328;
}
.searsia__result--open .searsia__toggle::before {
  content: "\e930";
}

.searsia__playbutton {
  bottom: 0;
  cursor: pointer;
  height: 32px;
  left: auto;
  line-height: 34px;
  margin: auto;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: auto;
  transition-property: background-color, opacity;
  transition-duration: 200ms;
  width: 32px;
}
.searsia__playbutton-icon::before {
  bottom: 0;
  cursor: pointer;
  height: 32px;
  width: 32px;
  left: auto;
  padding-top: 8px;
  padding-left: 1px;
  position: absolute;
  right: 0;
  top: auto;
  text-align: center;
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e951";
  font-size: 16px;
  transition: color 200ms;
}

.searsia__result--video > a {
  text-decoration: none;
  display: block;
}
.searsia__result--video > a:hover .searsia__title {
  color: inherit;
}
.searsia__result--video > a:hover .searsia__playbutton {
  animation: none !important;
  background-color: var(--color-white);
  opacity: 1;
}
.searsia__result--video > a:not(:hover) .searsia__playbutton-icon {
  animation: none !important;
  color: var(--color-white);
}

.searsia__title {
  color: #1e2328;
  display: block;
  font-weight: 600;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searsia__results--multiline-title .searsia__title {
  white-space: normal;
}

.searsia__desc {
  display: block;
  max-height: 50px;
  overflow: hidden;
  line-height: 25px;
  text-overflow: ellipsis;
}
@media (max-width: 960px) {
  .searsia__desc {
    max-height: 75px;
  }
}
@media (max-width: 750px) {
  .searsia__desc {
    max-height: 100px;
  }
}
.searsia__results--multiline-title .searsia__desc {
  max-height: none;
}
.searsia__desc a {
  color: var(--color-dark-grey);
}

.searsia__desc-prefix {
  font-style: italic;
}
.searsia__desc-prefix::after {
  content: " ";
}

.searsia__link {
  border-color: #dcddde;
  border-style: solid;
  border-width: 0 0 1px;
  display: inline-block;
  font-size: 13px;
  max-width: 100%;
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searsia__link a {
  color: inherit;
  text-decoration: none;
}

.searsia__icon {
  background: center/16px 16px no-repeat #f0f1f2;
  border: 1px solid #dcddde;
  color: #1e2328;
  display: inline-block;
  font-size: 12px;
  height: 24px;
  line-height: 24px !important;
  margin-bottom: -1px;
  margin-right: 10px;
  text-align: center;
  vertical-align: bottom;
  width: 24px;
}
.searsia__icon[class*="-14px"] {
  font-size: 14px;
}
.searsia__icon--ut {
  background-image: url("img/ut.svg");
}

.searsia__peopledetails {
  clear: both;
  cursor: auto;
  height: 1px;
  margin-left: -20px;
  margin-right: -20px;
  overflow: hidden;
  padding-top: 21px;
  position: relative;
}
.searsia__peopledetails::before {
  background-color: #dcddde;
  content: "";
  display: block;
  height: 1px;
  left: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
}
.searsia__result--open .searsia__peopledetails {
  height: auto;
}

.searsia__peopledetail {
  background-color: #f7f8f8;
  display: block;
  line-height: 20px;
  padding: 15px 20px;
  position: relative;
  text-decoration: none;
}
.searsia__peopledetail::after {
  background-color: #dcddde;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 20px;
  position: absolute;
  right: 20px;
}

a.searsia__peopledetail:hover {
  color: #1e2328;
}
a.searsia__peopledetail:hover .searsia__peopledetail-icon {
  color: #53575b;
}

.searsia__peopledetail-icon {
  color: #afb1b3;
  float: right;
  font-size: 24px;
  height: 30px;
  position: relative;
  top: -2px;
  width: 30px;
}

.searsia__phone b {
  font-weight: 700;
}

.searsia__imagegrid {
  position: relative;
  /* fallback if imagegrid js code is not loaded */
}
.searsia__imagegrid__item {
  display: inline-block;
  text-decoration: none;
  position: relative;
  height: 215px;
}
.searsia__imagegrid img {
  vertical-align: bottom;
}
.searsia__imagegrid > .searsia__imagegrid__item {
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
}
.searsia__imagegrid > .searsia__imagegrid__item img {
  height: inherit;
  width: auto;
}

.searsia__related {
  margin: 40px 0;
}
.searsia__related__title {
  display: block;
  font-size: 13px;
  font-weight: 600;
  height: 36px;
  line-height: 36px;
}
.searsia__related__link {
  color: #53575b;
  cursor: pointer;
  display: block;
  font-size: 15px;
  height: 36px;
  line-height: 36px;
}
.searsia__related__link:hover {
  color: inherit;
}
.searsia__related__linkicon {
  color: #afb1b3;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.searsia__filtered {
  color: #53575b;
  margin: 40px 0;
  font-style: italic;
}

.searsia__widget,
.widget.searsia__widget {
  border: 1px solid #dcddde;
  margin-top: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
}
@media (max-width: 960px) {
  .searsia__widget,
  .widget.searsia__widget {
    margin-bottom: 20px;
    position: static;
    width: 100%;
  }
}
.searsia__widget--loading {
  display: none;
}

.searsia__widget--highlight {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  position: static;
  width: 100%;
}
@media (max-width: 480px) {
  .searsia__widget--highlight {
    flex-direction: column-reverse;
  }
}

.searsia__highlight-text {
  background: #f7f8f8;
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 50%;
}
@media (max-width: 960px) {
  .searsia__highlight-text {
    flex: 1;
  }
}
@media (max-width: 480px) {
  .searsia__highlight-text {
    flex: none;
    width: 100%;
  }
}

.searsia__highlight-title {
  color: inherit;
  font-size: 26px; /*2.6rem*/
  font-weight: 700;
  line-height: 100%;
  text-decoration: none;
  text-transform: uppercase;
}
.searsia__highlight-title b {
  font-weight: 700;
}
@media (max-width: 480px) {
  .searsia__highlight-title {
    font-size: 18px;
  }
}

.searsia__highlight-description {
  margin-top: 10px;
}

.searsia__highlight-link {
  color: #53575b;
  font-size: 18px;
  font-weight: 300;
  margin-top: 10px;
  text-decoration: underline;
}

.searsia__highlight-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: none;
  min-height: 260px;
  position: relative;
  width: 50%;
}
@media (max-width: 960px) {
  .searsia__highlight-image {
    width: 33.3333%;
  }
}
@media (max-width: 480px) {
  .searsia__highlight-image {
    height: auto;
    min-height: auto;
    padding-top: 55.32%;
    width: 100%;
  }
}

.searsia__highlight-suggested {
  background-color: #1e2328;
  bottom: 0;
  color: #f0f1f2;
  font-size: 13px;
  line-height: 30px;
  padding: 0 15px;
  position: absolute;
  right: 0;
}

.searsia__widget--cta {
  background: #f0f1f2;
  padding: 30px;
}
@media (max-width: 750px) {
  .searsia__widget--cta {
    padding: 20px;
  }
}
.searsia__widget--cta .inlinecta__title {
  font-size: 26px;
  font-weight: 300;
  line-height: 26px;
  text-transform: uppercase;
}
.searsia__widget--cta .inlinecta__title b {
  font-weight: 700;
}
@media (max-width: 750px) {
  .searsia__widget--cta .inlinecta__title {
    font-size: 18px;
    line-height: 18px;
  }
}
.searsia__widget--cta .inlinecta__button {
  border: none;
  border-radius: 20px;
  color: var(--color-white);
  height: 40px;
  line-height: 36px;
}
.searsia__widget--cta .inlinecta__description {
  border-top: 1px solid var(--color-grey-lines);
  font-style: italic;
  margin: 30px -30px 0;
  padding: 25px 30px 0 50px;
  position: relative;
}
.searsia__widget--cta .inlinecta__icon {
  font-size: 60px;
  left: -21px;
  position: absolute;
  top: 20px;
  width: 60px;
}

.searsia__widget--location .widget__photoslide {
  display: block;
}
@media (max-width: 960px) {
  .searsia__widget--location .widget__title {
    height: 100px;
  }
}
@media (max-width: 960px) and (min-width: 481px) {
  .searsia__widget--location .widget__front {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .searsia__widget--location .widget__photos {
    flex: none;
    height: 100px;
    width: 150px;
  }
  .searsia__widget--location .widget__title {
    flex: 1;
  }
  .searsia__widget--location .widget__list {
    border-color: #dcddde;
    border-style: solid;
    border-width: 1px 0 0 0;
    flex: none;
    width: 100%;
  }
  .searsia__widget--location .widget__listitem:first-child {
    border-top-width: 0;
  }
}

.searsia__widget--event .widget__listitem {
  padding: 15px 0;
}
.searsia__widget--event .widget__listitem--link::before {
  display: none;
}
.searsia__widget--event .widget__listitem-text {
  color: #1e2328;
  font-size: 15px;
  font-weight: 600;
}
.searsia__widget--event .widget__listitem-subtext {
  color: #53575b;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
}