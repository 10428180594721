  .rtdcontent--iaaj > p
, .rtdcontent--iaaj > ul
, .rtdcontent--iaaj > ol
, .page-iaajregistration p.normal
, .page-iaajregistration ul.unordered
, .page-iaajregistration ol.ordered
, .iaajregistration__cta__link
{
  color: #777777;
  font: 16px/23px "Univers Next W02", Arial, Helvetica, sans-serif;
}

  .rtdcontent--iaaj > p.intro
, .page-iaajregistration p.intro
{
  color: #666666;
  font: 20px/34px "Univers Next W02", Arial, Helvetica, sans-serif;
}

  p.decorativeheading-black
, p.decorativeheading-color
{
  font: 700 36px/39px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0;
}

p.decorativeheading-black.decorativeheading-black
{
  color: #000000;
}

p.decorativeheading-color.decorativeheading-color
{
  color: #CF0072;
}



.iaajregistration__cta__link
{
  /* @include wh-form-hidenativecontrol; */
  display: inline-block;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;

  color: #5a5e62;

  text-decoration: underline;
}

.iaajregistration__cta__link:hover
{
  color: inherit;
}
