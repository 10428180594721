.iaajregistration__addtocalendar {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  width: min-content;
  flex-wrap: wrap;
}

.iaajregistration__addtocalendar .cta__button {
  display: flex;
  width: max-content;
  padding-left: 32px;
  padding-right: 32px;
  align-self: center;
  height: 44px;
  position: relative;
  z-index: 2;
}

.iaajregistration__addtocalendar .cta__button .icon {
  font-size: 30px;
  margin-right: 15px;
}

.wh-addtocalendar__triggerdropdown {
  display: block;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.add-to-calendar-dropdown.add-to-calendar-dropdown {
  display: none;
  position: absolute;
  z-index: 1;
  top: 20px;
  bottom: auto;
  padding-top: 31px;
  border: 1px solid #CCCCCC;
  background-color: #F5F5F5;
}

.add-to-calendar-dropdown:focus {
  outline: 0;
}

.add-to-calendar-dropdown:focus-visible {
  outline: 0;
  border: 1px solid #888888;
}

.wh-addtocalendar--showdropdown .add-to-calendar-dropdown {
  display: block;
}

.add-to-calendar-dropdown a {
  display: block;
  white-space: nowrap;
}