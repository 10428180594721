/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.iaajregistration__form__heading {
  font: 600 18px "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.iaajregistration__idea .iaajregistration__form__heading {
  font: 600 16px "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
}

html.page--iaaj {
  scroll-padding-top: 65px;
  scroll-snap-padding-top: 65px;
}
html.page--iaaj input[required],
html.page--iaaj textarea[required] {
  background-color: #FFFFF5;
}
html.page--iaaj .wh-form__fieldgroup:first-child {
  margin-top: 0;
}
html.page--iaaj .main__contents {
  padding-bottom: 60px;
}

html.page--iaajregistration .main__contents {
  background-color: #666666;
}

.registrationpages,
.registrationpage--type,
.iaajregistration__header,
.iaajregistration__info {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}

.iaajregistration__info > * {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
}
@media (max-width: 960px) {
  .iaajregistration__info > * {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .iaajregistration__info > * {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}

.iaajregistration__intro {
  --layout: 8col;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 640px;
  /*
  Activate when 1440+ design becomes available for all pages
    @media (min-width: $responsive-xxmedium-devices)
    {
      width: $largescreen-width-8col;
    }
  */
  width: 620px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 750px) {
  .iaajregistration__intro {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .iaajregistration__intro {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .iaajregistration__intro {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}

.iaajregistration__intro,
.iaajerrorpage {
  text-align: center;
  margin-bottom: 60px;
}

.iaajregistration__intro form,
.iaajregistration__intro a.cta__button {
  margin-top: 30px;
}

.iaajregistration__typeselection,
.iaajregistration__asktogether,
.iaajregistration__success {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 960px) {
  .iaajregistration__typeselection,
  .iaajregistration__asktogether,
  .iaajregistration__success {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .iaajregistration__typeselection,
  .page--toplevelhome .iaajregistration__asktogether,
  .page--toplevelhome .iaajregistration__success {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}

.iaajregistration__typeselection {
  margin-bottom: 35px;
}

.iaajregistration__workshop,
.iaajregistration__idea,
.iaajregistration__userinfo,
.iaajregistration__nominee_heading {
  margin-top: 20px;
}

.iaajregistration__workshop {
  /* this broke mobile layout forms - https://gitlab.webhare.com/utwente/utwente_base/-/issues/1853
    .wh-form__page.wh-form__page--visible .wh-form__fieldgroup--richtext .wh-form__richtext
    {
      display: block;
    }
  */
}
.iaajregistration__workshop .wh-form__fieldgroup--checkbox::before {
  display: none;
}
.iaajregistration__workshop .wh-form__label {
  flex: 1 1 auto;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.iaajregistration__workshop .wh-form__fieldgroup > .wh-form__label {
  text-align: left;
  padding-right: 0px;
  padding-bottom: 4px;
}
.iaajregistration__workshop .wh-form__fieldgroup {
  flex-direction: column;
}
.iaajregistration__workshop .wh-form__fields {
  width: 100%;
  max-width: 100%;
  min-width: 0;
  flex: 1 0 auto;
}
.iaajregistration__workshop .wh-form__fieldgroup + .wh-form__fieldgroup,
.iaajregistration__workshop .openday__round + .openday__round {
  margin-top: 20px;
}
.iaajregistration__workshop .wh-form__buttongroup {
  text-align: left;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-left: 0;
}
.iaajregistration__workshop .wh-form__buttongroup .wh-form__button {
  /* why was this minwidth needed? it's also to wide for the prereg widget eg on https://www.utwente.nl/en/education/study-choice-calendar/online-open-days/#register-open-days
  min-width: 270px;
  */
  margin-right: 0;
}
.iaajregistration__workshop .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 0;
  margin-bottom: 10px;
}
.iaajregistration__workshop .wh-form__buttongroup .wh-form__button--previous {
  position: relative;
  right: auto;
  float: none;
  order: 3;
  margin-top: 0;
  text-align: left;
}

.iaajregistration__workshop .iaajregistration__form__moreinfo {
  margin-bottom: 20px;
}

.iaajregistration__idea .iaajregistration__form__heading {
  margin-bottom: 20px;
}

.iaajregistration__idea textarea {
  margin-top: 20px;
}

/*
.iaajregistration__uploadimage
{
  align-self: center;
}
*/
.iaajregistration__uploadimage .iaajregistration__form__heading {
  font-weight: 700;
  font-size: 18px;
  text-transform: none;
}

/******************************************************************
**
**  Conditionally visible parts of the form
*/
html:not(.iaajreg--online.iaajreg--online) [data-group=online] {
  display: none;
}

html:not(.iaajreg--visit.iaajreg--visit) [data-group=together] {
  display: none;
}

html:not(.iaajreg--visittogether.iaajreg--visittogether) [data-group=nominee] {
  display: none;
}

html:not(.iaajreg--typeselected.iaajreg--typeselected) [data-group=typeselected] {
  display: none;
}

.iaajregistration__success {
  display: none;
}

html.iaajreg--registered .iaajregistrationform {
  display: none;
}
html.iaajreg--registered .iaajregistration__success {
  display: block;
}

/*
  html.iaajreg--typeselected .iaajregistration__info
, html.iaajreg--registered .iaajregistration__info
{
  display: none;
}
*/
.iaajregistrationform {
  padding-bottom: 50px;
}

.iaajregistration__fluff .iaajregistration__form__heading {
  margin-bottom: 20px;
}

.iaajregistration__fluff {
  padding-right: 25px;
}

.iaajregistration__fluff > p.normal,
.iaajregistration__fluff > ul.normal,
.iaajregistration__fluff > ol.normal {
  color: #5A5E62;
  font: 400 18px/30px Arial;
}

.rtdcontent > *:first-child {
  margin-top: 0;
}

.rtdcontent > *:last-child {
  margin-bottom: 0;
}

.cta__button {
  flex: none;
}

@media (max-width: 799px) {
  .iaajregistration__asktogether > * {
    margin-top: 40px;
  }
  .iaajregistration__asktogether .blockbutton + .blockbutton {
    margin-top: 20px;
  }
  html.page--iaaj .blockbutton--type .blockbutton__content {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 23px;
  }
  .iaajregistration__typeselection {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  .iaajregistration__typeselection > * {
    flex: 0 1 100%;
  }
  .iaajregistration__typeselection > .blockbutton--closed {
    margin-top: 40x;
    order: 2;
  }
}
@media (min-width: 800px) {
  .iaajregistration__typeselection {
    width: 1220px;
    display: flex;
  }
  .iaajregistration__typeselection > * {
    flex: 0 1 50%;
  }
  .iaajregistration__typeselection > * + * {
    margin-left: 25px;
  }
  html.page--iaaj .blockbutton__content {
    padding-top: 15.5%;
    padding-bottom: 10.5%;
    padding-left: 14%;
    padding-right: 14%;
  }
  .iaajregistration__asktogether {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 20px;
    column-gap: 25px;
    row-gap: 20px;
  }
  .iaajregistration__workshop__zipcitycountry {
    display: flex;
    align-items: top;
    margin-top: 20px;
  }
  .iaajregistration__workshop__zipcitycountry > * {
    flex: 1 0 0px;
    margin-top: 0 !important;
  }
  .iaajregistration__workshop__zipcitycountry > * + * {
    margin-left: 25px;
  }
}
/***************************************************************
**
**  Together switch
*/
html.page--iaaj .blockbutton--together .blockbutton__title {
  text-transform: none;
}

.iaajregistration__asktogether input,
.iaajregistration__asktogether textarea {
  width: 100%;
  font: inherit;
}

.iaajregistration__asktogether textarea {
  padding: 3px 10px;
  height: 165px;
}

/***************************************************************
**
**  Layout
*/
.iaajregistration__info,
.iaajregistration__scrollto,
.iaajregistration__spacer,
.iaajregistration__userinfo,
.iaajregistration__workshop,
.iaajregistration__nominee_heading,
.iaajregistration__nominee_reason,
.iaajregistration__idea,
.iaajregistration__uploadimage {
  grid-column: 1/-1;
}

.iaajregistration__submit {
  /*
  grid-column: 1 / -1;
  justify-self: center;
  */
  display: flex;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  height: 55px;
  border-radius: 26px;
}