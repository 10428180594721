.iaajregistration__together {
  background: #FAFAFA;
  border: 2px solid #C5C5C5;
  box-shadow: 0 23px 34px 0 rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  padding: 55px 30px;
  text-align: center;
  position: relative;
}

.iaajregistration__together__confetti {
  position: absolute;
  width: 151px;
  height: 192px;
  top: 0;
  right: 0;
  transform: translate(25px, -53px);
}

.iaajregistration__together__title {
  color: #1E2328;
  font: 400 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
  margin-bottom: 35px;
}

.iaajregistration__together__description {
  color: #5A5E62;
  font: 400 15px/23px "Univers Next W02", Arial, Helvetica, sans-serif;
}

.iaajregistration__together .iaajregistration-switch {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}